$(function () {
    if (Cookies.get('ngsf') == 'true') {
        $('.filter_btn').trigger('click');
        Cookies.remove('ngsf');
    }

    $(document).on('change', 'form#fixProductListForm', function (e) {
        e.preventDefault();
        if ($('html').hasClass('show-filter') === true) {
            Cookies.set('ngsf', true);
        }

        window.location = '?' + $.param($(this).serializeArray());
    });
});