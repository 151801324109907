/**
 * Handle the main page, communications and render. Like a Jquery plugin
 * 
 * @param {JQueryStatic<HTMLElement>} $ 
 * @param {JQuery<HTMLElement>} tabs
 * @param {MainPageFilterServerCommunication} mainPageFilterServerCommunication
 * @param {MainPageFilterRender} mainPageFilterRender
 */
function MainPageFilter($, tabs, mainPageFilterServerCommunication, mainPageFilterRender) {

    /** @type {JQueryStatic<HTMLElement>}  */
    this.tabs = tabs;
    /**
     * @type {MainPageFilterServerCommunication}
     */
    this.mainPageFilterServerCommunication = mainPageFilterServerCommunication;

    this.mainPageFilterRender = mainPageFilterRender;

    /**
     * This array is containing the change watched element's. In tab pane
     * 
     * @type Array<JQueryStatic<HTMLElement>>
     */
    this.changeTrackedInTab = [];

    //Help, for accessing to self objec everyver
    let self = this;

    this.init = function () {
        this.tabs.on('change', this.onTabChagne);
        //Fake event, to first init;
        this.tabs.first().change();

        this.rimsInit
    }

    /**
     * Handle the changed tabs
     */
    this.onTabChagne = function () {
        //Mark input checked
        self.tabs.removeAttr('checked');

        $(this).attr('checked', 'checked');

        filterTargetTab = $(this).data('target');

        let activeTab = $(filterTargetTab);

        self.mainPageFilterServerCommunication.types = $(this).val();
        self.mainPageFilterServerCommunication.season = -1;
        self.mainPageFilterServerCommunication.size = -1;
        self.mainPageFilterServerCommunication.diameter = -1;

        activeTab.find('.navigateToProductSearch').click(function (event) {

            event.preventDefault();

            let url = self.mainPageFilterServerCommunication.createUrl($('.mainpage-filters').data('search-navigate-url'));

            window.location.href = url;
        });


        self.initTabForm(activeTab);

        //self.createQueryToServerWithAndRender();

    }

    /**
     * Init the active tab
     * 
     * @param {JQuery<HTMLElement>} tab 
     */
    this.initTabForm = function (tab) {
        // find category id!
        self.unsubscribeTabFromTabEvents();

        let form = $(tab.find('form'));

        let season = $(form.find('[name="season"]'));
        let size = $(form.find('[name="size"]'));
        let diameter = $(form.find('[name="diameter"]'));
        let discount = $(form.find('[name="discount_active"]'));

        size.val('-1');
        diameter.val('-1');

        //Set Parameters  to render
        self.mainPageFilterRender.season = season;
        self.mainPageFilterRender.sizeSelect = size;
        self.mainPageFilterRender.diameterSelect = diameter
        self.mainPageFilterRender.setTab(tab);
        self.mainPageFilterRender.enableFields();

        //After change get the vaules, default listing.
        self.mainPageFilterServerCommunication.season = $(form.find('[name="season"]:checked')).val();
        self.mainPageFilterServerCommunication.size = size.val();
        self.mainPageFilterServerCommunication.diameter = diameter.val();

        self.createQueryToServerWithAndRender();

        //Reset the size and the diameter
        tab.find('.cancelSelect.sizeSelect').click(function (e) {
            e.preventDefault();

            size.val('-1');
            size.change();
        });

        tab.find('.cancelSelect.diameterSelect').click(function (e) {
            e.preventDefault();
            diameter.val('-1');
            diameter.change();
        });

        //Subscribe to changes
        season.change(function () {

            self.mainPageFilterServerCommunication.season = $(this).val();


            size.val("-1");

            self.mainPageFilterServerCommunication.size = "-1";
            diameter.val("-1");
            self.mainPageFilterServerCommunication.diameter = "-1";

            self.createQueryToServerWithAndRender();
        });

        size.change(function () {

            self.mainPageFilterServerCommunication.size = $(this).val();

            if (self.mainPageFilterServerCommunication.size != '-1') {
                self.mainPageFilterRender.ignoringFromRender = 'sizeSelect';
            }
            self.createQueryToServerWithAndRender('size');
        });

        discount.change(function () {
            self.mainPageFilterServerCommunication.discount = $(this).prop('checked');
            
            self.createQueryToServerWithAndRender('discount');
        });

        diameter.change(function () {

            self.mainPageFilterServerCommunication.diameter = $(this).val();

            if (self.mainPageFilterServerCommunication.diameter != '-1') {
                self.mainPageFilterRender.ignoringFromRender = 'diameterSelect';
            }
            self.createQueryToServerWithAndRender('diameter');
        });

        this.changeTrackedInTab.push(season, size, diameter);
    };

    /**
     * This, method is create a query to server, and help render, with Render Object
     */
    this.createQueryToServerWithAndRender = function (changedSelect) {

        self.mainPageFilterRender.disableFields();

        self.mainPageFilterServerCommunication.getNumberOfHitsFromServer(changedSelect).then(function (response) {
            self.mainPageFilterRender.renderSearchNumberResoults(response);
        }).catch(function (response) {
            self.mainPageFilterRender.renderSearchNumberResoults({
                count: 0
            });

        }).always(function () {
            self.mainPageFilterRender.enableFields();

        });
    }

    /**
     * Important, unscubscribe not used tabs event.
     */
    this.unsubscribeTabFromTabEvents = function () {

        for (let jqueryElement of this.changeTrackedInTab) {
            jqueryElement.unbind("change");
            console.log(jqueryElement);
        }
        this.changeTrackedInTab = [];
    };


}



(
    /**
     * 
     * Init the main page filter
     * 
     * Blade File: mainpage-filters.blade.php
     * 
     * 
     * @param {JQueryStatic<HTMLElement>} $
     */
    function ($) {

        $(document).ready(function () {

            let mainPageFilters = $('.mainpage-filters');

            if (mainPageFilters.data('search-url') === undefined) {
                throw new Error("The search url is needed!");
            }
            if (mainPageFilters.data('search-method') === undefined) {
                throw new Error("The search method is needed!");
            }

            let filterTab = mainPageFilters.find('[name="filter-tabs"]');
            let filterTargetTab = $('[name="filter-tabs"]:checked').data('target');

            let activeTab = $(filterTargetTab);
            let activeForm = activeTab.find('form');

            let mainPageFilterServerCommunication = new MainPageFilterServerCommunication($, mainPageFilters.data('search-url'), mainPageFilters.data('search-method'));

            let mainPageFilterRender = new MainPageFilterRender($);

            let mainPageFilter = new MainPageFilter($, filterTab, mainPageFilterServerCommunication, mainPageFilterRender);

            mainPageFilter.init();





            /*

            let filterController = new MainPageFilterController($, mainPageFilters.data('search-url'), mainPageFilters.data('search-method'));

            let filterTab = mainPageFilters.find('[name="filter-tabs"]');
            let filterTargetTab = $('[name="filter-tabs"]:checked').data('target');

            let activeTab = $(filterTargetTab);
            let activeForm = activeTab.find('form');

            let renderHelper = new ViewHelper($);


            renderHelper.targetElement = activeTab;

            filterController.ajaxSuccess = renderHelper.renderSearchNumberResoults;
            filterController.ajaxError = renderHelper.errorSearchHandling;

            //First tab init.
            //activeForm.bind("change", {
            //    filterController: filterController,
            //    $: $
            //}, mainpageFilterFormChangeHandler);

            let size = activeForm.find('name=["meret"]');
            let radius = activeForm.find('name=["atmero"]');

            //Set active type
            filterController.types = filterTab.val();

            filterController.getNumberOfHitsFromServer();

            filterTab.change(function () {
                //Mark input checked
                filterTab.removeAttr('checked');
                $(this).attr('checked', 'checked');

                //Rmove handler from active form
                //activeForm.unbind("change", mainpageFilterFormChangeHandler);

                filterController.resetFormDatas();

                filterController.types = $(this).val();
                filterTargetTab = $(this).data('target');

                activeTab = $(filterTargetTab);
                //Set active form, and add events
                activeForm = activeTab.find('form');

                renderHelper.targetElement = activeTab;

                let formParameters = activeForm.serializeArray();

                for (let formParameter of formParameters) {
                    switch (formParameter.name) {
                        case 'meret':
                            filterController.size = formParameter.value;
                            break;
                        case 'season':
                            filterController.season = formParameter.value;
                            break;
                        case 'atmero':
                            filterController.diameter = formParameter.value;
                            break;
                    };
                }

                if (filterController.season === undefined) {
                    filterController.season = -1;
                }

                activeForm.bind("change", {
                    filterController: filterController,
                    $: $
                }, mainpageFilterFormChangeHandler);

                filterController.getNumberOfHitsFromServer();
            });

            //If send form, we redirect to search page
            mainPageFilters.find('form').submit(function (event) {
                console.log("This is it!");
                event.preventDefault();

                let url = filterController.createUrl(mainPageFilters.data('search-navigate-url'));
                //TODO: Ezt engedélyezd vissza. 
                //window.location.href = url;
            });*/
        });
    })(jQuery);