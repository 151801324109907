(
    /**
     *
     * Init the Can We Help form ajax sending.
     *
     * Blade File: need_help.blade.php
     *
     *
     * @param {JQueryStatic<HTMLElement>} $
     */
    function ($) {


        $(document).ready(function () {
            /**
             * Process submit form datas
             */
            $(".needHelpForm").submit(function (event) {

                event.preventDefault();
                //Get form URL
                if ($(this).attr('action')) {
                    let URL = $(this).attr('action');
                } else {
                    throw new Error("Sorry! The action tag is not defined in this element");
                }
                //This porperty-s is required to send!
                let sendableParametersDefault = {
                    'name': '',
                    'email': '',
                    'phoneNumber': '',
                    'textMessage': '',
                    'callMe': 0,
                };

                let serializedArray = $(this).serializeArray();

                let sendObj = {};

                for (let sendabelparamterName in sendableParametersDefault) {

                    let formItem = serializedArray.find(function (item) {
                        return item.name == sendabelparamterName;
                    });

                    if (formItem !== undefined && formItem.value !== undefined && formItem.value !== null) {
                        sendObj[sendabelparamterName] = formItem.value;
                    } else {
                        sendObj[sendabelparamterName] = sendableParametersDefault[sendabelparamterName];
                    }
                }


                let self = this;
                //Reset the form
                $(this).find('[name]').removeClass('error');
                $(this).find('[data-error-for]').addClass('hidden').html("");
                $(this).find('.formSendSuccess').addClass('hidden').html("");

                let submitButton = $(this).find('[type=submit]');

                submitButton.prop('disabled', true);

                $.ajax({
                    method: $(this).attr('method') ? $(this).attr('method') : "POST",
                    url: $(this).attr('action'),
                    data: sendObj,
                    cache: false,
                    success: function (response) {
                        submitButton.prop('disabled', false);
                        fbq( 'trackCustom', 'NeedHelp' );
                        $(self).find('.formSendSuccess').removeClass('hidden').html(response);

                        $(self).find("input[type=text],input[type=email], textarea").val("");
                    },
                    error: function (response) {
                        submitButton.prop('disabled', false);

                        if (response.status == HTTP_UNPROCESSABLE_ENTITY) {
                            for (let errorFiledName in response.responseJSON) {
                                let errorValue = response.responseJSON[errorFiledName];

                                let errorElement = $(self).find('[name="' + errorFiledName + '"]');
                                let errorLabel = $(self).find('[data-error-for="' + errorFiledName + '"]');

                                errorElement.addClass('error');

                                errorLabel.removeClass('hidden').html(errorValue);
                            }
                        }

                    }
                })

            });
        });
    }
)(jQuery);
