/**
 * Help rendering the view
 * 
 * @param {JQueryStatic<HTMLElement>} $ 
 */
function MainPageFilterRender($) {
    this.tab;

    this.season;
    this.sizeSelect;
    this.diameterSelect;
    this.resoultNumber;
    /**
     * Ignored the select from render. For example if you chouse an option from size select you don't need update.
     */
    this.ignoringFromRender;

    const defaultSizeSelect = $('<option></option>').attr('value', "-1").html('Méret'); //INFO: If you need translation, you neet get the first item parameters
    const defaultDiameterSelect = $('<option></option>').attr('value', "-1").html('Átmérő'); //INFO: If you need translation, you neet get the first item parameters

    const sizeSelectIgnoreName = "sizeSelect";
    const diameterSelectIgnorName = "diameterSelect"
    let self = this;

    this.setTab = function (tab) {
        this.tab = tab;

        this.resoultNumber = $(this.tab.find('.resoultsNumber'));
    }

    this.renderSearchNumberResoults = function (resoult) {
        this.resoultNumber.html(resoult.count);
        console.log("ignored:", this.ignoringFromRender);
        //select size value updateing
        if (this.ignoringFromRender !== sizeSelectIgnoreName) {

            let selectSizeSelectValue = this.sizeSelect.val();

            this.sizeSelect.html(defaultSizeSelect);
            let has = false;
            for (let size of resoult.sizes) {
                let option = $('<option></option>').attr('value', size).html(size);
                this.sizeSelect.append(option);
                if (size == selectSizeSelectValue) {
                    has = true;
                }
            }

            this.sizeSelect.val(has ? selectSizeSelectValue : "-1");
        }

        //select diameter value updateing

        if (this.ignoringFromRender !== diameterSelectIgnorName) {
            let selectDiameterValue = this.diameterSelect.val();
            this.diameterSelect.html(defaultDiameterSelect);
            let has = false;
            for (let diameter of resoult.radiuses) {
                let option = $('<option></option>').attr('value', diameter).html(diameter);
                this.diameterSelect.append(option);
                if (diameter == selectDiameterValue) {
                    has = true;
                }
            }
            this.diameterSelect.val(has ? selectDiameterValue : "-1");
        }

        this.ignoringFromRender = undefined;
    }

    this.errorSearchHandling = function (error) {
        
        self.renderSearchNumberResoults(0);
    }

    this.disableFields = function () {
        
        this.season.prop('disabled', true);
        this.sizeSelect.prop('disabled', true);
        this.diameterSelect.prop('disabled', true);
        $('.cancelSelect').prop('disabled', true);


    };
    this.enableFields = function () {
        
        this.season.prop('disabled', false);
        this.sizeSelect.prop('disabled', false);
        this.diameterSelect.prop('disabled', false);
        $('.cancelSelect').prop('disabled', false); 
    }
}