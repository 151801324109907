;
(function ($) {

  $(document).ready(function () {
    var isMobile = {
      Android: function() {
          return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
          return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
          return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function() {
          return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };

    if (isMobile.any() && $('.productSearchResoultsListing').length > 0) {
      if ($('.productSearchResoultsListing .product-item-wide').length > 2) {
        $('.productSearchResoultsListing .product-item-wide').waypoint(function (direction) {
          if (direction === 'down' && $(this)[0].key === 'waypoint-2' && !$('#floatedWheelBottomBar').hasClass('in')) {
            $('#floatedWheelBottomBar').animate({
              'bottom': '0px'
            }).addClass('in');
          }
        }, {
          offset: '100%'
        });
      } else {
        $('.productSearchResoultsListing .product-item-wide').waypoint(function (direction) {
          if (direction === 'down' && $(this)[0].key === 'waypoint-0' && !$('#floatedWheelBottomBar').hasClass('in')) {
            $('#floatedWheelBottomBar').animate({
              'bottom': '0px'
            }).addClass('in');
          }
        }, {
          offset: '25%'
        });
      }
    }
    

    $('.collapse-arrow').click(function (e) {
      e.preventDefault();

      $(this).fadeOut(300, function () {
        $(this).remove();
      });

      $('.row.collapse').slideDown(300);
      $('.title-mobile small').slideUp(300);

      if ($('.why-we .why-we-item').length > 0 && isMobile.any()) {  
        preload(
          '/images/nettogumi/whywe_1-W.png',
          '/images/nettogumi/whywe_2-W.png',
          '/images/nettogumi/whywe_3-W.png',
          '/images/nettogumi/whywe_4-G.png'
        );
  
        $('.why-we .why-we-item').waypoint(function (direction) {
          var element = $(this)[0].element;
          if ($(element).hasClass('animated')) return;
  
          var image = $(element).data('image');
          var borderColor = $(element).data('border-color');
          var $circle = $(element).find('.circle');
  
          $(element).find('.circle > img').fadeOut(300);
          var $image = $(`<img alt="" src="${image}" style="opacity:0;" />`);
          $(element).find('.circle').append($image);
          $image.animate({
            'opacity': 1
          }, 300, function () {
            $(element).find('.circle > img').first().remove();
          });
  
          $circle.css('border-color', borderColor);
  
          $(element).addClass('animated');
  
        }, {
            offset: '50%'
          });
  
        function preload() {
          var images = [];
          for (var i = 0; i < arguments.length; i++) {
            images[i] = new Image();
            images[i].src = preload.arguments[i];
          }
        }
      }
      
    });

    if ($(".most-popular-carousel").length > 0 && isMobile.any()) {
      $(".most-popular-carousel").owlCarousel({
        center: true,
        items: 1,
        margin: 10,
        loop: false,
        dots: false,
        stagePadding: 40,
      });
    }

    $(".open-mobile-menu").click(function () {
      $(".mobile-menu-wrapper").slideToggle(500, function () {});
    });

    if ($('.testimonials-slider').length > 0) {
      var testimonialsSlider = $('.testimonials-slider').bxSlider({
        'controls': false,
        'pager': true,
        'adaptiveHeight': false,
        'auto': true,
        'infiniteLoop': true,
        'onSlideAfter': function () {
          testimonialsSlider.stopAuto();
          testimonialsSlider.startAuto();
        },
        'speed': 500,
        'pause': 8000
      });
    }

    $(function () {
      //$('.filter_btn').on('click', function (e) {
      //  e.preventDefault();
      //  if ($('html').hasClass('show-filter')) {
      //    $('html').removeClass('show-filter');
      //    stopBodyScrolling(false, 'overlay');
      //    $('#overlay').remove();
      //  } else {
      //    $('html').addClass('show-filter');
      //    $('body').prepend('<div id="overlay"></div>');
      //    stopBodyScrolling(true, 'overlay');
      //  }
      //  return false;
      //});
    });

    if ($('.addtocart').length > 0) {
      $('.addtocart').magnificPopup({
        type: 'inline',
        mainClass: 'mfp-with-zoom mfp-addedcart',
        closeOnContentClick: false,
        closeBtnInside: true,
        preloader: false,
        fixedContentPos: false,
        callbacks: {
          beforeOpen: function () {
            $('body').css('overflow', 'hidden');
          },
          beforeClose: function () {
            $('body').css('overflow', 'auto');
          }
        }
      });
    }

    // Open link in new window
    if ($('.new-window').length > 0) {
      $(function () {
        $('a.new-window').click(function () {
          window.open(this.href);
          return false;
        });
      });
    }

    $(".delivery-info").click(function () {
      $(".delivery-info-details").slideToggle(700, function () {});
    });

    // Scroll to top
    $(function () {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
          $('#back-top').fadeIn();
        } else {
          $('#back-top').fadeOut();
        }
      });
      $('#back-top').click(function () {
        $('body,html').animate({
          scrollTop: 0
        }, 800);
        return false;
      });
    });

    $('.popup-image').magnificPopup({
      type: 'image',
      mainClass: 'mfp-with-zoom producImage',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      image: {
        markup: '<div class="mfp-figure">' +
          '<div class="mfp-close"></div>' +
          '<div class="info-bar"></div>' +
          '<div class="mfp-img"></div>' +
          '</div>',
        verticalFit: true,
        cursor: ''
      },
      callbacks: {
        markupParse: function (template, values, item) {
          template.find('.info-bar').html('');
          var logo = item.el.data("logo");
          var logo_out = '';
          var title = item.el.data("title");
          var title_out = '';
          var meret = item.el.data("meret");
          var meret_out = '';
          var link = item.el.data("link");
          var link_out = '';
          if (logo.length > 0) {
            logo_out = '<img src="' + logo + '">';
          }
          if (title.length > 0) {
            title_out = '<h2>' + title + '</h2>';
          }
          if (meret.length > 0) {
            meret_out = '<strong>' + meret + '</strong>';
          }
          if (link.length > 0) {
            link_out = '<a href="' + link + '">Részletek &raquo;</a>';
          }
          template.find('.info-bar').append('<table><tr><td>' + logo_out + '</td><td class="title">' + title_out + '</td><td>' + meret_out + '</td><td>' + link_out + '</tr></table>');
        }

      }
    });

    $('.popup-gallery').magnificPopup({
      type: 'image',
      mainClass: 'mfp-with-zoom producImage',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      image: {
        markup: '<div class="mfp-figure">' +
          '<div class="mfp-close"></div>' +
          '<div class="info-bar"></div>' +
          '<div class="mfp-img"></div>' +
          '<div class="mfp-nav mfp-next"></div>' + 
          '<div class="mfp-nav mfp-prev"></div>' + 
          '</div>',
        verticalFit: true,
        cursor: ''
      },
      callbacks: {
        markupParse: function (template, values, item) {
          template.find('.info-bar').html('');
          var logo = item.el.data("logo");
          var logo_out = '';
          var title = item.el.data("title");
          var title_out = '';
          var meret = item.el.data("meret");
          var meret_out = '';
          var link = item.el.data("link");
          var link_out = '';
          
          if (logo && logo.length > 0) {
            logo_out = '<img src="' + logo + '">';
          }
          if (title && title.length > 0) {
            title_out = '<h2>' + title + '</h2>';
          }
          if (meret && meret.length > 0) {
            meret_out = '<strong>' + meret + '</strong>';
          }
          if (link && link.length > 0) {
            link_out = '<td><a href="' + link + '">Részletek &raquo;</a></td>';
          }
          template.find('.info-bar').append('<table><tr><td>' + logo_out + '</td><td class="title">' + title_out + '</td><td>' + meret_out + '</td>' + link_out + '</tr></table>');

          let currentImage = 0;
          const images = [item.el.attr('href'), ...item.el.data('images')];

          $('body').on('click', '.mfp-next', function () {
            if (images.length > currentImage + 1) {
              currentImage++;
              template.find('.mfp-img').attr('src', images[currentImage]);
            }
          });

          $('body').on('click', '.mfp-prev', function () {
            if (currentImage > 0) {
              currentImage--;
              template.find('.mfp-img').attr('src', images[currentImage]);
            }
          });
        }

      }
    });

    $('.popup-youtube').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-with-zoom',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
      disableOn: function () {
        if ($(window).width() < 600) {
          return true;
        }
        return true;
      }
    });

    if ($('.floated-cart').length > 0) {
      if ($('.promotion-wrapper').length > 0)
        var height = ($('.promotion-wrapper').offset().top) + ($('.promotion-wrapper').height() / 2);
      else
        var height = 400;
      
      window.onscroll = function () {
        if ($(this).scrollTop() > height) {
          $('.floated-cart').addClass('animate');
        } else {
          $('.floated-cart').removeClass('animate');
        }
      };
    }

    function stopBodyScrolling(bool, item) {
      if (bool === true) {
        document.getElementById(item).addEventListener("touchmove", freezeVp, false);
      } else {
        document.getElementById(item).removeEventListener("touchmove", freezeVp, false);
      }
    }

    var freezeVp = function (e) {
      e.preventDefault();
    };


    $( document ).on( 'click', '.toggle-description', function( event ) {
      event.preventDefault();
      $( this ).parents( '.product-description' ).first().find( '.product-detailed-description' ).slideToggle();
    } );
  });



  /**
  * Cookie-warning
  */
  function setCookie(key, value, _expireDays) {
    const expireDays = typeof _expireDays === undefined ? 7 : _expireDays;      
    Cookies.set(key, value, { expires: expireDays, path: '/' });
  }

  function getCookie(key) {
    return Cookies.get(key);
  }

  $("#cookie-close").on("click", function (e) {
      setCookie('cookiebar','1', 365);
      $("#cookie-warning").hide();

  });

  if (getCookie('cookiebar') == null) {
      $("#cookie-warning").removeClass('hidden');
  }

  $('.product-url-list-page').click(function (e) {
    e.preventDefault();
    productClick($(this).data(), $(this).attr('href'));
  });

  if ($('.product-url-list-page').length > 0) {
    let products = [];

    $('.product-url-list-page').each(function () {
      products.push($(this).data());
    });

    //Enhanced Ecommerce plugin
    addImpressions(products);
  }

})(jQuery);
