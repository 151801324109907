$(function () {
    $(document).on('click', '.carSelectButtonProductList', function (e) {
        e.preventDefault();

        const productId = $(this).data('product-id');
        const qty = $('select.quantityOfProduct').length > 0 ? $('select.quantityOfProduct').val() : 4;

        $.ajax({
            url: `/wheel/car-select-poup`,
            type: 'GET',
            dataType: 'JSON',
            data: {
                quantity: qty
            },
            success: function (response) {
                addWheelToCart(productId, qty);
                initCarSelectPopup(response, productId, qty);
            }
        });

    });
});

function addWheelToCart(productId, qty) {
    $.ajax({
        url: '/cart/product/show-cart-modal/' + productId,
        type: 'POST',
        dataType: 'JSON',
        data: {
            quantity: qty
        },
        success: function (response) {
            if (response.status === 'OK') {
                $('.countOfCartProduct').html(response.countOfProduct);
            }
        }
    });
}

function initCarSelectPopup(response, productId, qty) {
    $.magnificPopup.open({
        items: {
            src: response.html,

        },
        closeOnContentClick: false,
        closeBtnInside: true,
        preloader: false,
        //fixedContentPos: false,
        callbacks: {
            beforeOpen: function () {
                $('body').css('overflow', 'hidden');
            },
            open: function () {
                $('body').addClass('magnificpopupnoscroll');
                //Specific modal buttons
                $(".return-shop-btn").click(function () {
                    $.magnificPopup.close();
                });

                loadBrands();
                saveCarDetails(productId);

                $(document).on('click', '#vechicleNotFoundToggle', function (e) {
                    e.preventDefault();

                    $('.notFoundCar').slideToggle();
                });
               
            },
            close: function () {
                $('body').removeClass('magnificpopupnoscroll');
            },
            beforeClose: function () {
                $('body').css('overflow', 'auto');
            },
            afterClose: function () {
                //Bad overflow remove, fix the auto overflow
                $('body').css('overflow', 'unset');
            }
        },
        mainClass: 'mfp-with-zoom mfp-addedcart mfp-carselect',
        type: 'inline',
    });
}

function saveCarDetails(productId)
{
    $(document).on('change', 'form#carSelectForm', function () {

        const brandSelect = $(this).find('select[name="brand"]');
        const typeSelect = $(this).find('select[name="type"]');
        const modelSelect = $(this).find('select[name="model"]');

        console.log("Event: Save car details");

        $.ajax({
            url: `/wheel/save-car-details`,
            type: 'POST',
            dataType: 'JSON',
            data: {
                brand: brandSelect.val() != '-1' ? brandSelect.find('option:selected').html() : null,
                type: typeSelect.val() != '-1' ? typeSelect.find('option:selected').html() : null,
                model: modelSelect.val() != '-1' ? modelSelect.find('option:selected').html() : null,
                notFoundCar: $('input[name="notFoundCar"]').val(),
                wheelAssembly: $('input[name="wheelAssembly"]').prop('checked'),
                productId
            },
            success: function (response) {
                console.log(response);
            }
        });
    });
}