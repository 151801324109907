(function ($) {    
    $(document).ready(function () {

        $('.scrollToElement').click(function (e) {
            e.preventDefault();

            const $target = $(this).data('target');

            $('html, body').animate({
                scrollTop: $($target).offset().top
            }, 1000);
        });

        let options = {
            method: "POST",
            eventCb: function (self) {
                //console.log($(self).data('product-id'), $('.quantityOfProduct').val());

                //Ecommerce plugin
                addToCart({
                    id: $(self).data('id'),
                    name: $(self).data('name'),
                    category: $(self).data('category'),
                    brand: $(self).data('brand'),
                    quantity: $('.quantityOfProduct').val()
                });

                return output = {
                    url: '/cart/product/show-cart-modal/' + $(self).data('product-id'),
                    data: {
                        productId: $(self).data('product-id'),
                        quantity: $('.quantityOfProduct').val()
                    }
                };

            },
            success: function () {
                console.log("Success");
            }
        };
        
        let customAddToCartButton = $(".classCustomAddToCart");

        customAddToCartButton.getCartModal(options);
        let productDetails = $('.productDetails');
        let quantityOfProduct = productDetails.find('.quantityOfProduct');

        quantityOfProduct.productPriceCalculator({
            targetSelector: '.calculatedPriceOfProduct',
            beforeCalculatePrice: function () {
                customAddToCartButton.prop('disabled', true);
            },
            calculatedPriceCompleteCallback: function () {
                customAddToCartButton.prop('disabled', false);
            },
            calculatedPriceErrrCallback: function () {
                customAddToCartButton.prop('disabled', false);
            }
        });

        let setPrice = productDetails.find('.setPriceText');
        let notSetPriceText = productDetails.find('.notSetPriceText');
        let priceTextNumberOfProducQty = productDetails.find('.priceTextNumberOfProducQty');


        //DANGER:THIS is not the best way!

        let countOfCartProduct = $('.countOfCartProduct');
        let deliverText = $('.deliveryText');
        let discountRateContainer = $('#discountRateContainer');
        let arrowText = $('#arrowText');
        const sale = $('.low-price-package').data('sale') == 1;
        quantityOfProduct.change(function () {
            let value = $(this).val();
            const productLabel = $('.product-main-info-wrapper').data('product-type') === undefined ? 'gumi' : $('.product-main-info-wrapper').data('product-type');

            if (sale === true) {
                arrowText.html('Utolsó darabok');
                discountRateContainer.html('AKCIÓBAN');
                if (productLabel == 'wheels')
                    setPrice.html(`<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db együtt</small> Gumi + felni`);
                else
                    setPrice.html(`<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db ${productLabel}</small> Ára:`);
            } else {
                setPrice.removeClass('hidden');
            
                if (value == 1)
                    $('.piece-price').hide();
                else
                    $('.piece-price').show();

                setPrice.html(function () {
                    if (productLabel == 'wheels') {
                        return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db együtt</small> Gumi + felni`;
                    }else {
                        if (value == 1) return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db ${productLabel}</small> Ára:`;
                        else return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db ${productLabel} ára</small> Akcióban:`;
                    }
                });

                discountRateContainer.html(function () {
                    if (value <= 4) return `-${discountRateContainer.data('discount').toMoney(0, '.', '.')} <small>Ft-tal</small>`;
                    else return `-${((parseInt(discountRateContainer.data('discount')) / 4) * value).toMoney(0, '.', '.')} <small>Ft</small>`;
                });

                arrowText.html(function () {
                    if (productLabel == 'wheels') {
                        return `Gumi + felni olcsóbb`;
                    }

                    if (value <= 3) return '4 db-tól olcsóbb!';
                    else if (value == 4) return 'Szettben olcsóbb!';
                    else return 'Ennyit spórolhatsz';
                });
            }

            let countOfCartProductNumber = parseInt(countOfCartProduct.html());
            let actualProductQty = parseInt($(this).val());

            if (!isNaN(countOfCartProductNumber)) {
                if ((countOfCartProductNumber + actualProductQty) >= 4) {
                    deliverText.html(
                        deliverText.data('delivery-free')
                    );
                } else {
                    deliverText.html(
                        deliverText.data('delivery')
                    );
                }
            }


        });

        quantityOfProduct.change();
    });

    Number.prototype.toMoney = function (decimals, decimal_sep, thousands_sep) {
        var n = this,
            c = isNaN(decimals) ? 2 : Math.abs(decimals),
            d = decimal_sep || '.',
            t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            sign = (n < 0) ? '-' : '',

            i = parseInt(n = Math.abs(n).toFixed(c)) + '',

            j = ((j = i.length) > 3) ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    }

})($);