(function ($) {
    /**
     * After doc loaded, init the cart remove button and change quaninty select.
     */
    $(document).ready(function () {

        $('.coupon-button').click(function (e) {
            e.preventDefault();

            const code = $('input[name="coupon"]').val().trim();
            const deliveryMethod = $('input[name="deliveryMethod"]:checked').val();
            const $button = $(this);

            $button.attr('disabled', true);

            $.ajax({
                url: `/cart/coupon-add`,
                type: 'POST',
                dataType: 'JSON',
                data: {
                    code,
                    deliveryMethod
                },
                success: function (response) {
                    console.log(response);

                    if (response.status === 'INVALID_COUPON') {
                        $('.coupon-result-container').html('Helytelen kupon');
                        $button.attr('disabled', false);
                    } else if(response.status === 'OK') {
                        $('#cart_coupon_item_container').html(response.html);
                        $('.price-summary').html(response.final_price_formatted);
                        $('#couponContainer input').attr('disabled', true);
                        $('.coupon-result-container').html('Kuponkód jóváhagyva');
                    }
                }
            });
        });

        let options = {
            method: "POST"
        };

        options.eventCb = function (self) {
            let outputOptions = {};

            outputOptions.url = "cart/product/remove/" + $(self).data('product-id');

            return outputOptions;
        };

        options.success = function (response, clickedButton) {
            if (response.removed == "OK") {
                location.reload();
            }
        };

        $(".removeCartButtonInCartPage").removeCartButton(options);
        //Change quantyti select.
        let changeQuantitySelectOptions = {
            trigger: 'change',
            method: 'POST',
            eventCb: function (self) {
                return {
                    url: 'cart/product/change-quantity/' + $(self).data('product-id'),
                    data: {
                        quantity: $(self).val()
                    },
                };
            },
            success: function (response) {
                console.log(response);
                if (response.status == "OK") {
                    location.reload();
                }
            }
        };

        $(".changeCartProductQuantity").changeQuantityCartSelect(changeQuantitySelectOptions);

        $('input[name="deliveryMethod"]').change(function () {
            const appointmentNeeded = $(this).attr('data-appointment-needed') == 1;
            const appointmentNeededWrapper = $('.appointment-needed-wrapper');

            if (appointmentNeeded === true) {
                appointmentNeededWrapper.removeClass('hidden');
            } else {
                if(!appointmentNeededWrapper.hasClass('hidden'))
                    appointmentNeededWrapper.addClass('hidden');
            }
        });

        $('input[name="deliveryMethod"]').change(function () {
            const deliveryMethodId = $(this).val();

            $.ajax({
                url: '/cart/get-delivery-method',
                type: 'POST',
                dataType: 'JSON',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    deliveryMethodId
                }, success: function (data) {
                    $('#cart_delivery_item_container').html(data.html);
                    $('span.price-summary').html(data.price_sum);
                    Cookies.set('deliveryMethodId', deliveryMethodId, { expires: 1, path: '/' });
                }
            });
        });

        $('.cart-data-box .radio-button-wrapper').hover(function () {
            $(this).find('.tooltip').css('display', 'flex').hide().fadeIn(250);
        }, function () {
            $(this).find('.tooltip').hide();
        });

        $('#checkoutBtn').click(function (e) {
            e.preventDefault();
            
            $('#deliveryMethodForm').submit();
        });

        if (Cookies.get('deliveryMethodId') !== undefined) {
            console.log(Cookies.get('deliveryMethodId'));
            $(`input[name="deliveryMethod"][value="${Cookies.get('deliveryMethodId')}"]`).prop('checked', true).change();
            console.log('change');
        }
    });
})($)