(
    /**
     * @param {jQuery} $
     */
    function ($) {

        $(document).ready(function () {

            $('body').on('click', '.floated-cart.wheel .close', function (e) {
                e.preventDefault();

                $('.floated-cart.wheel').animate({
                    'height': '0px',
                    'bottom': '-60px'
                }, 300, "linear", function () {
                    setTimeout(function () {
                        $('.floated-cart.wheel').remove();

                        $('.floated-cart-sm').animate({
                            'bottom': '0px'
                        }, 300);
                    }, 1000);
                });
            });

            $('body').on('click', '.relatedProductCartButton', function (e) {
                e.preventDefault();
    
                const $button = $(this);
                const productId = $button.data('product-id');
                const quantity = $button.parents('.button-wrapper').find('select[name="piece"]').val();
    
                $.ajax({
                    url: `/cart/product/add/${productId}`,
                    type: 'POST',
                    dataType: 'JSON',
                    data: {
                        quantity
                    },
                    success: function (response) {
                        if (response.status === 'OK') {
                            $button.parents('.button-wrapper').after(response.html);
                            $button.parents('.button-wrapper').remove();
                            $('.countOfCartProduct').html(response.countOfProduct);
                        }
                    }
                });
            });

            $('body').on('click', '.deleteRelatedProductCartButton', function (e) {
                e.preventDefault();

                const $button = $(this);
                const productId = $button.data('product-id');

                $.ajax({
                    url: `/cart/product/remove/${productId}`,
                    type: 'POST',
                    dataType: 'JSON',
                    success: function (response) {
                        if (response.removed === 'OK') {
                            $button.parents('.added-alert-text').after(response.html);
                            $button.parents('.added-alert-text').remove();
                            $('.countOfCartProduct').html(response.countOfProduct);
                        }
                    }
                });
            });

            let options = {
                method: "POST",
                eventCb: function (self) {

                    return output = {
                        url: '/cart/product/show-cart-modal/' + $(self).data('product-id'),
                        data: {
                            productId: $(self).data('product-id'),
                            quantity: $('.customAddToCartFloatedQuantity').val()
                        }
                    };
                }
            };

            let customAddToCartFloated = $('.customAddToCartFloated');

            customAddToCartFloated.getCartModal(options);

            let floatedCartDetails = $('.floatedCartDetails');

            let customAddToCartFloatedQuantity = floatedCartDetails.find('.customAddToCartFloatedQuantity');

            //console.log(floatedCartDetails, customAddToCartFloatedQuantity);


            //INFO: Need some information need two URL, one of one product tow of 4 product.
            customAddToCartFloatedQuantity.change(function () {
                let floatedImage = $('.floatedCartImage');
                if ($(this).val() >= 4) {
                    floatedImage.attr('url', floatedImage.data('set-image-url'));
                } else {
                    floatedImage.attr('url', floatedImage.data('thumbnail-url'));
                }
            });



            customAddToCartFloatedQuantity.productPriceCalculator({
                targetSelector: '.calculatedPriceOfProduct',
                beforeCalculatePrice: function () {
                    customAddToCartFloated.prop('disabled', true);
                },
                calculatedPriceCompleteCallback: function () {
                    customAddToCartFloated.prop('disabled', false);
                },
                calculatedPriceErrrCallback: function () {
                    customAddToCartFloated.prop('disabled', false);
                }
            });

            let setPrice = floatedCartDetails.find('.setPriceText');
            let notSetPriceText = floatedCartDetails.find('.notSetPriceText');
            let priceTextNumberOfProducQty = floatedCartDetails.find('.priceTextNumberOfProducQty');

            let arrowText = $('.floatedCartDetails .low-price-package .first');
            let discountRateContainer = $('.floatedCartDetails .low-price-package .last');
            const sale = $('.floatedCartDetails .low-price-package').data('sale') == 1;

            customAddToCartFloatedQuantity.change(function () {
                let value = $(this).val();
                const productLabel = $('.product-main-info-wrapper').data('product-type') === undefined ? 'gumi' : $('.product-main-info-wrapper').data('product-type');

                if (sale === true) {
                    arrowText.html('Utolsó darabok');
                    discountRateContainer.html('AKCIÓBAN');
                    setPrice.html(`<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db gumia</small> Ára:`);
                } else {
                    setPrice.removeClass('hidden');
                
                    if (value == 1)
                        $('.piece-price').hide();
                    else
                        $('.piece-price').show();
                        
                    setPrice.html(function () {
                        if (productLabel == 'wheels') {
                            return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db együtt</small> Gumi + felni`;
                        } else if (productLabel == 'felni') { 
                            if (value == 1) return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db felni</small> Ára:`;
                            else return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db felni ára</small> Akcióban:`;
                        }else {
                            if (value == 1) return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db gumi</small> Ára:`;
                            else return `<small class=""><span class="priceTextNumberOfProducQty">${value}</span> db gumi ára</small> Akcióban:`;
                        }
                    });
    
                    discountRateContainer.html(function () {
                        if (value <= 4) return `-${discountRateContainer.data('discount').toMoney(0, '.', '.')} <small>Ft-tal</small>`;
                        else return `-${((parseInt(discountRateContainer.data('discount')) / 4) * value).toMoney(0, '.', '.')} <small>Ft</small>`;
                    });
    
                    arrowText.html(function () {
                        if (productLabel == 'wheels') {
                            return `Gumi + felni olcsóbb`;
                        }
                        
                        if (value <= 3) return '4 db-tól olcsóbb!';
                        else if (value == 4) return 'Szettben olcsóbb!';
                        else return 'Ennyit spórolhatsz';
                    });
                }

                /* priceTextNumberOfProducQty.html(value);

                if (value >= 4) {
                    setPrice.removeClass('hidden');
                    notSetPriceText.addClass('hidden');
                } else {
                    notSetPriceText.removeClass('hidden');
                    setPrice.addClass('hidden');
                } */
            });
            customAddToCartFloatedQuantity.change();
        });

    }
)($);