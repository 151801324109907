/**
 * Handle loader on list page.
 * 
 * This method is dependent the bootstrap 3.x css or .hidden{display:none} class.
 * 
 * @param {JQuery} $
 * @param {JQueryStatic<HTMLElement>} element
 */
function ListPageProductLoader($, element) {

    if (element === undefined) {
        element = $('.productLoader');
    }

    this.element = element;

    let showLoader = false;

    var freezeVp = function (e) {
        e.preventDefault();
    };


    function stopBodyScrolling(bool, item) {
        if (bool === true) {
            document.getElementById(item).addEventListener("touchmove", freezeVp, false);
        } else {
            document.getElementById(item).removeEventListener("touchmove", freezeVp, false);
        }
    }

    this.showLoader = function () {

        if (showLoader == false) {
            this.element.removeClass('hidden');
            showLoader = true;
        }
    }
    this.hideLoader = function () {

        if (showLoader == true) {
            this.element.addClass('hidden');

            showLoader = false;

        }
    }

}