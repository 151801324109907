/**
 * Handling the main page filter
 * 
 * @param {JQueryStatic<HTMLElement>} $
 * @param {string} url
 * @param {string} method
 */
function MainPageFilterServerCommunication($, url, method) {
    this.url = url;
    this.method = method;

    ///
    this.types;
    this.season;
    this.size;
    this.diameter;
    this.discount;

    this.ajaxSuccess
    this.errorSuccess


    if ($ === undefined) {
        throw new Error("The JQuery is required!");
    }

    if (url === undefined) {
        throw new Error("The URL is required!");
    }
    if (method === undefined) {
        throw new Error("The send method is required! Exmaple:'GET','POST','PUT'.....");
    }


    /**
     * Create and url, where navigate the User when click the search button.
     * 
     * @param {string} page
     * @return {string}
     */
    this.createUrl = function (page) {
        let getJson = {};

        let querData = this.toQueryData();

        if (querData.season !== undefined && !Array.isArray(querData.season)) {
            querData.season = [querData.season];
        }

        return (page ? page : '') + '?' + $.param(querData);
    }

    /** 
     * Helper method, dump the query data.
     */
    this.dumpQueryDatas = function () {
        console.log(this.toQueryData());
    }
    /**
     * Ajax query, get the number of product by setted parameters
     * 
     * @param {callback} success
     * @param {callback} error
     * @return JQuery.jqXHR
     */
    this.getNumberOfHitsFromServer = function (changedSelect) {

        return $.ajax({
            url: this.url,
            method: this.method,
            data: this.toQueryData(changedSelect),
            //success: this.ajaxSuccess ? this.ajaxSuccess : function () {},
            //error: this.ajaxError ? this.ajaxError : function () {}
        });
    }

    /**
     * Create Query Datas JSON
     * @return {{[key:string]:string|number}}
     * 
     */
    this.toQueryData = function (changedSelect) {
        let output = {};


        if (this.types !== undefined) {
            output['category'] = this.types;
        }
        if (this.season !== undefined && this.season != -1 && this.season != '') {
            output['season'] = this.season;
        }
        if (this.size !== undefined && this.size != '-1' && this.size != '' && this.size !== null) {
            output['size'] = this.size;
        }

        if (this.diameter !== undefined && this.diameter !== '-1' && this.diameter != '' && this.diameter !== null) {
            output['diameter'] = this.diameter;
        }

        if (this.discount !== undefined && this.discount !== '-1' && this.discount != '' && this.discount !== null) {
            output['discount'] = this.discount;
        }

        if (changedSelect !== undefined) {
            output.changed = changedSelect;
        }

        return output;
        //return {
        //    kategoria: this.types,
        //    season: this.season,
        //    size: this.size,
        //    diameter: this.diameter,
        //}
    }

    this.resetFormDatas = function () {
        this.types = undefined;
        this.season = undefined;
        this.size = undefined;
        this.diameter = undefined;
    };
}