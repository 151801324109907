/**
 *
 * Passing errors to input fileds
 *
 * @param {JQueryStatic<HTMLElement>} $
 * @param {JQueryStatic<HTMLElement>} container
 * @param {[key:string]:any} errors
 * @return void
 */
function passErrorsToInput($, container, errors) {

    if (errors == undefined) {
        return;
    }

    $('.checkoutErrorTexts').remove();

    let firstErrorInputElement = null;
    for (let inputName in errors) {

        let input = container.find('input[name="' + inputName + '"]');
        if (input.length > 0 && firstErrorInputElement == null) {
            firstErrorInputElement = input;
        }

        if ($(input).next().is('span')) {
            input = $(input.next());
        }

        let errorString = recursiveGetErrorFormResponse(errors[inputName]);
        input.after("<p class='checkoutErrorTexts'>" + errorString + "</p>");

    }


    $('html, body').animate({
        scrollTop: firstErrorInputElement.offset().top
    }, 2000);





}

/**
 * Get all respons get all string in deep
 *
 *
 * @return string
 */
function recursiveGetErrorFormResponse(error) {

    let outputString = "";
    if (typeof error == "string") {
        return error;
    } else if (typeof error == "object") {
        for (let subErrorIndex in error) {
            outputString += recursiveGetErrorFormResponse(error[subErrorIndex]);
        }
    }

    return outputString;
}

/**
 * @param {JQueryStatic<HTMLElement>} $
 */
function checkoutHandler($) {

    let disabledQuery = false;
    let checkoutContainer = $(".checkoutContainer");



    let deliveryAddress = checkoutContainer.find('.deliveryAddresses');


    //Default dont show
    let deliveryAddressIsEqualInvoicingAddress = $('.deliveryAddressIsEqualInvoicingAddress');



    deliveryAddressIsEqualInvoicingAddress.change(function () {
        if ("deliveryAddressIsEqualInvoicingAddress value:", $(this).prop('checked')) {
            deliveryAddress.addClass('hidden');
        } else {
            deliveryAddress.removeClass('hidden');
        }
    });

    let checkoutForm = $(".checkoutForm");

    checkoutForm.submit(
        /**
         * @param {JQuery.EventHandler<TElement>} e
         */
        function (e) {

            e.preventDefault();

            let self = $(this);
            let submitButton = self.find(':submit');


            if (disabledQuery) {
                return;
            }

            let ajaxQuery = $.ajax({
                url: self.attr('action'),
                data: self.serializeArray(),
                method: self.attr('method')
            });

            submitButton.prop('disabled', true);
            disabledQuery = true;
            ajaxQuery.then(function (response) {

                if (response.status = "OK") {

                    if (response.order && response.order.products) {
                        ecPurchase(response.order.products, response.order);
                    }

                    disabledQuery = true;

                    fbq('track', 'Purchase', {
                        value: $('.checkoutForm .price-summary').text().replace(/ |\.|Ft/g, ''),
                        currency: 'HUF',
                    });
                    //Wait before redirect!
                    setTimeout(function () {
                        if (response.redirect) {
                            location.href = response.redirect;
                        }
                    }, 2000);

                } else {
                    disabledQuery = false;
                }
            }).catch(function (response) {
                submitButton.prop('disabled', false);

                disabledQuery = false;
                if (response.status == 422) {
                    passErrorsToInput($, self, response.responseJSON);
                } else {
                    $(".errorInQuery").html("Sajnáljuk, hiba történt a feldolgozás közben, kérlek próbáld meg később.");
                }

            }).always(function () {


            });
        }
    );

    let shwoPriceWithPaymentContainer = $('.checkoutProductContainer');
    let checkoutFormSubmitButton = checkoutForm.find(':submit');
    //If change a status of payment type and has additional price, we need to show this price.
    let paymentType = checkoutForm.find('[name=paymentType]');

    paymentType.change(function () {

        let checkoutUrl = checkoutForm.data('show-price-with-payment-url');

        if (checkoutUrl === undefined) {
            throw new Error("The data-show-price-with-payment-url is not set! Set the URL!");
        }
        checkoutFormSubmitButton.prop('disabled', true);
        console.log($(this).val());
        //make an ajax query, to get new full price, and cart data
        $.ajax({
            method: "POST",
            url: checkoutUrl,
            data: {
                paymentMethodId: $(this).val()
            }
        }).then(function (response) {
            if (response.status == "OK") {
                shwoPriceWithPaymentContainer.html(response.html);
            }
        }).catch(function (errorRespone) {

            shwoPriceWithPaymentContainer.html('');
        }).always(function () {
            checkoutFormSubmitButton.prop('disabled', false);
        });
    });

    if (paymentType[0] !== undefined) {
        $(paymentType[0]).change();
    }
}

$(document).ready(function () {
    checkoutHandler($);
});


//$(".checkoutContainer")