$(function () {
    const wrapper = $('.mainpage-wheels-filters');

    wrapper.find('form').change(function (e) {

        const category = wrapper.find('form input[name="category"]').val();
        const season = wrapper.find('form input[name="season"]:checked').val();
        const size = wrapper.find('form select[name="size"]').val();
        const diameter = wrapper.find('form select[name="diameter"]').val();

        $.ajax({
            url: `/ajax-wheels-search-count-hits`,
            type: 'POST',
            dataType: 'JSON',
            data: {
                category,
                season,
                size: size == '-1' ? undefined : size,
                diameter: diameter == '-1' ? undefined : diameter,
            },
            success: function (response) {
                wrapper.find('.resoultsNumber').html(response.count);

                if (response.count === 0) {
                    wrapper.find('form select[name="size"]').html(`<option value="-1">Méret</option>`);
                    wrapper.find('form select[name="diameter"]').html(`<option value="-1">Átmérő</option>`);
                    return;
                }

                if (e.target.name === 'season') {
                    wrapper.find('form select[name="size"]').html(`<option value="-1">Méret</option>`);
                    wrapper.find('form select[name="diameter"]').html(`<option value="-1">Átmérő</option>`);

                    response.sizes.forEach(size => {
                        wrapper.find('form select[name="size"]').append(`<option value="${size}">${size}</option>`);
                    });

                    response.radiuses.forEach(radius => {
                        wrapper.find('form select[name="diameter"]').append(`<option value="${radius}">${radius}</option>`);
                    });
                } else if (e.target.name === 'size') {
                    wrapper.find('form select[name="diameter"]').html(`<option value="-1">Átmérő</option>`);
                    response.radiuses.forEach(radius => {
                        wrapper.find('form select[name="diameter"]').append(`<option value="${radius}">${radius}</option>`);
                    });
                }

            }
        });

    });

    wrapper.find('form .navigateToProductSearch').click(function (e) {
        e.preventDefault();

        const url = wrapper.data('search-navigate-url');

        let category, season, size, diameter;
        let output = {};

        if( (category = wrapper.find('form input[name="category"]').val()) != '-1'){
            output['category'] = category;
        }

        if( (season = wrapper.find('form input[name="season"]:checked').val()) != '-1'){
            output['season'] = [season];
        }

        if( (size = wrapper.find('form select[name="size"]').val()) != '-1'){
            output['size'] = size;
        }

        if( (diameter = wrapper.find('form select[name="diameter"]').val()) != '-1'){
            output['diameter'] = diameter;
        }

        window.location = url + '?' + $.param(output);
        return false;
    });

    
});
