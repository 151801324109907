$(function() {
  $(document).mouseleave(function() {
    if (
      $(".mfp-savecart").length == 0 &&
      !$("body").hasClass("savecart-opened")
    ) {
      $.ajax({
        url: "/cart/save-cart-modal",
        type: "GET",
        dataType: "JSON",
        data: {
          _token: $('meta[name="csrf-token"]').attr("content")
        },
        success: function(response) {
          if (response.countOfProduct > 0 && response.status === "OK") {
            $("body").addClass("savecart-opened");
            $.magnificPopup.open({
              items: {
                src: response.html
              },
              closeOnContentClick: false,
              closeBtnInside: true,
              preloader: false,
              //fixedContentPos: false,
              callbacks: {
                beforeOpen: function() {
                  $("body").css("overflow", "hidden");
                },
                beforeClose: function() {
                  $("body").css("overflow", "auto");
                }
              },
              mainClass: "mfp-with-zoom mfp-addedcart mfp-savecart",
              type: "inline"
            });
          }
        }
      });
    }
  });

  $(document).on("click", ".mfp-savecart .btn-save-cart", function(e) {
    e.preventDefault();

    $.ajax({
      url: `/save-cart`,
      type: `POST`,
      dataType: "JSON",
      data: {
        _token: $('meta[name="csrf-token"]').attr("content"),
        email: $('.mfp-savecart input[name="email"]')
          .val()
          .trim()
      },
      success: function(response) {
        $("#saveCartForm .row").html(`<p>${response.message}</p>`);
      }
    });
  });
});
