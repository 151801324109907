/**
 * Typedef for settings
 *  
 * @typedef {Object} ProductPriceCalculatorOptions
 * @property {string} targetSelector Help for selecting and drawing incomming visuabel datas 
 * @property {function} beforeCalculatePrice Before, send the  request, call this callback
 * @property {function} calculatedPriceCompleteCallback If we calculate the new price, we can subscribe the response
 * @property {function} calculatedPriceErrrCallback If the calculation request is wrong, called this method
 * @property {string} url the target url, this rute is called
 * @property {string} method the method of call
 * 
 * 
 * 
 */

/**
 * @param {JQueryStatic<HTMLElement>} $
 */
let productPriceCalculator = function ($) {

    /**
     * This method is help for calculating a product price, ad help for drawing this. 
     * @param {ProductPriceCalculatorOptions} options
     */
    $.fn.productPriceCalculator = function (options) {
        if (options == undefined) {
            options = {

            };
        }

        let defaultOptions = {
            method: "POST"
        }

        options = $.extend(true, defaultOptions, options);

        $(this).change(function () {

            if (options.beforeCalculatePrice !== undefined) {
                options.beforeCalculatePrice()
            }

            $.ajax({
                method: options.method,
                url: options.url !== undefined ? options.url : $(this).data('price-calculate-url'),
                data: {
                    qty: $(this).val()
                }
            }).then(function (response) {
                if (response.status == "OK") {
                    if (options.targetSelector !== undefined) {
                        $(options.targetSelector).html(response.formattedPrice)
                    }
                }

                if (options.calculatedPriceCompleteCallback !== undefined) {
                    options.calculatedPriceCompleteCallback(response);
                }
            }).catch(function (errorResponse) {


                if (options.targetSelector !== undefined) {
                    $(options.targetSelector).html('')
                }
                if (options.calculatedPriceErrrCallback !== undefined) {
                    options.calculatedPriceErrrCallback(response);
                }
            });
        });
    };
};


productPriceCalculator($);