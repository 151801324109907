/**
 * Netto gumi add to cart button plugin.
 * Help for add element to cart. Add Cart automaticaly to button if has class .addToCart
 * 
 * If you need use into program: $(element).addCartButton();
 * If yu need use delegate: $(element).addCartButton(delegate:'.selector');
 * 
 * NOTICE: the product id is required!
 * 
 */

/**
 * Typedef for settings
 *  
 * @typedef {Object} CartButtonOptions
 * @property {}
 * @property {string} delagate - If delegate add the selector for this
 * @property {string} trigger - Jquery event, like: click, hover etc...
 * @property {(response,self:HTMLElement)=>void} success -  Add succes callback method.
 * @property {(response,self:HTMLElement)=>void} error - Error ind adding
 * @property {string} url - Url of ajax query
 * @property {string} method - Method of ajax query like GET, POST etc... $.ajax
 * @property {{[key:string]:any}} data - Sendig datas to server, never use together with dataCb property
 * @property {(self)=>CartButtonOptions} eventCb - If you need any data to send server and change setting you can with this callback
 */

/**
 * 
 * The header menu, cart icon number of cart changer
 * 
 */
function updateCartNumberPreview($, count) {
    $(".countOfCartProduct").html(count);
};


/**
 * 
 * @param {JQueryStatic<HTMLElement>} $
 * @param {HTMLElement} self
 */
function createAjaxCartRequest($, self, settings) {

    return function () {



        $(self).attr('disabled', "disable");
        $(self).prop('disabled', true);

        let sendingData = {};

        if (settings.eventCb !== undefined && typeof settings.eventCb == 'function') {

            let eventCbSettings = settings.eventCb(this);

            if (eventCbSettings !== undefined) {
                settings = $.extend({}, settings, eventCbSettings);
            }

        }

        if (settings.data !== undefined) {
            sendingData = settings.data;
        }

        $.ajax({
            url: settings.url,
            method: settings.method ? settings.method : 'GET',
            data: sendingData,
            success: settings.success ?
                function (response) {

                    console.log(response);

                    settings.success(response, self);

                    fbq('track', 'AddToCart', {
                        value: response.priceOfProduct,
                        currency: 'HUF',
                        productId: response.productId
                    });


                    //Update count of products, on top right cart icon
                    if (response.countOfProduct !== undefined) {
                        updateCartNumberPreview($, response.countOfProduct);
                    }

                    $(self).removeAttr('disabled');
                    $(self).prop('disabled', false);
                } : function (response) {

                    //Update count of products, on top right cart icon
                    if (response.countOfProduct !== undefined) {
                        updateCartNumberPreview($, response.countOfProduct);
                    }

                    $(self).removeAttr('disabled');
                    $(self).prop('disabled', false);
                },
            error: settings.error ?
                function (response) {
                    settings.error(response, self)

                    $(self).removeAttr('disabled');
                    $(self).prop('disabled', false);
                } : function () {
                    $(self).removeAttr('disabled');
                    $(self).prop('disabled', false);
                }
        });
    }
};


/**
 * 
 * 
 * @param {JQueryStatic<HTMLElement>} $ 
 * @param {HTMLElement} self 
 * @param {CartButtonOptions} options 
 */
let queryHandler = function ($, self, options) {
    let defaultOption = {
        url: '',
        trigger: 'click',
    };

    let settings = $.extend(defaultOption, options);

    if (settings.delegate == undefined) {
        $(self).on(settings.trigger, createAjaxCartRequest($, self, settings));
    } else {
        $(self).delegate(settings.delegate, settings.trigger, createAjaxCartRequest($, self, settings));
    }
};


/**
 * @param {JQueryStatic<HTMLElement>} $
 */
(function ($) {
    /**
     * @param {CartButtonOptions} options
     * 
     */
    $.fn.addCartButton = function (options) {

        if (options === undefined) {
            options = {};
            options.method = "POST";
            options.eventCb = function (self) {
                let output = {
                    url: '/cart/add/product/' + $(self).data('product-id'),
                    data: {
                        quantity: 4
                    }
                };
                return output;
            };
        }
        options.url = queryHandler($, this, options);

        return this;
    };

    /**
     * 
     * @param {CartButtonOptions} options
     * 
     * Remove an element form Cart
     */
    $.fn.removeCartButton = function (options) {
        if (options === undefined) {
            options = {};

            options.method = "POST";

            options.eventCb = function (self) {
                let output = {
                    url: '/cart/product/remove/' + $(self).data('product-id'),
                };

                return output;
            };
        }

        queryHandler($, this, options);

        return this;
    };

    /**
     * Change Quantity for a product in cart
     * 
     * @param {CartButtonOptions} options
     */
    $.fn.changeQuantityCartSelect = function (options) {
        queryHandler($, this, options);

        return this;
    };

    //$(".changeQuantityCartSelect").removeCartButton();

    /**
     * Order Product in cart
     */
    $.fn.orederCartButton = function (option) {

        return this;
    };
    /**
     * Get cart modal(popup, and inti)
     * And add the product to cart, if added a product the cart we can change the unatity of product.
     * Handle this method.
     * 
     * @param {CartButtonOptions} options
     */
    $.fn.getCartModal = function (options) {

        if (options === undefined) {
            options = {};

            options.method = "POST";

            options.eventCb = function (self) {
                let output = {
                    url: '/cart/product/show-cart-modal/' + $(self).data('product-id'),
                    quantity: $(self).data('quantity')
                };

                return output;
            };
        }


        //Init cart modal
        options.success = function (response) {
            let popup = $.magnificPopup.open({
                items: {
                    src: response.html,

                },
                closeOnContentClick: false,
                closeBtnInside: true,
                preloader: false,
                //fixedContentPos: false,
                callbacks: {
                    beforeOpen: function () {
                        $('body').css('overflow', 'hidden');
                    },
                    open: function () {
                        $('body').addClass('magnificpopupnoscroll');


                        //Specific modal buttons
                        $(".return-shop-btn").click(function () {
                            $.magnificPopup.close();
                        });
                        //$(".changeCartProductQuantity").changeQuantityCartSelect(changeQuantitySelectOptions);
                        $('.changeQuantityButton').each(function () {

                            let options = {};
                            let self = $(this);
                            let cartItem = $(this).parents(".cartItem");

                            options.method = "POST";

                            options.eventCb = function (self) {

                                let quantity = $('.changeQuantity[data-product-id=' + $(self).data('product-id') + ']').val()
                                //If we click the button remove succes change 
                                $(self).parent().find('.successChangeNumber').remove();
                                let output = {
                                    url: '/cart/product/change-quantity/' + $(self).data('product-id'),
                                    data: {
                                        quantity: quantity
                                    }
                                };

                                return output;
                            };

                            options.success = function (response) {

                                if (response.status = "OK") {

                                    let element = self.parent();

                                    element.append('<div class="successChangeNumber success-change-number">.</div>');

                                    let changeablePrice = self.parents('.cart-items').find('.changeablePrice');

                                    changeablePrice.html(response.price);



                                    cartItem.find('.itemQty').html(response.changedQty);

                                    let fourPiecesTogether = cartItem.find(".fourPiecesTogether");
                                    let setPrice = cartItem.find('.setPrice');
                                    let showWrapper = cartItem.find('.showWrapper');

                                    if (response.changedQty >= 4) {
                                        setPrice.removeClass('hidden');
                                        fourPiecesTogether.removeClass('hidden');
                                        showWrapper.addClass('hidden');
                                    } else {
                                        setPrice.addClass('hidden');
                                        fourPiecesTogether.addClass('hidden');
                                        showWrapper.removeClass('hidden');
                                    }

                                    setTimeout(function () {
                                        element.find('.successChangeNumber').remove();
                                    }, 3500);
                                }
                            }


                            $(this).changeQuantityCartSelect(options);
                        });

                        $('#popup-addtocart select.changeQuantity').productPriceCalculator({
                            targetSelector: '#popup-addtocart .price-price .changeablePrice'
                        });
                    },
                    close: function () {
                        $('body').removeClass('magnificpopupnoscroll');
                    },
                    beforeClose: function () {
                        $('body').css('overflow', 'auto');
                    },
                    afterClose: function () {
                        //Bad overflow remove, fix the auto overflow
                        $('body').css('overflow', 'unset');
                    }
                },
                mainClass: 'mfp-with-zoom mfp-addedcart',
                type: 'inline',
            });
        }

        queryHandler($, this, options);

        return this;
    };



    //If The document loaded, init the scritp in default classes.
    $(document).ready(function () {
        $(".addToCart").addCartButton();
        $(".removeCartButton").removeCartButton();
        $('.showCartPopup').getCartModal();

        
        
        $('body').on('change', '#popup-addtocart select.changeQuantity', function () {
            const isTire = !$('.mfp-popup-addtocart').hasClass('rim') && !$('.mfp-popup-addtocart').hasClass('wheel');
            let value = $(this).val();

            let discountRateContainer = $('#popup-addtocart #discountRateContainer');
            let arrowText = $('#popup-addtocart #arrowText');
            let setPrice = $('#popup-addtocart .price-text');

            setPrice.html(function () {
                const tireText = isTire === true ? 'gumi' : ''; 

                if (value == 1) return `<span class="fourPiecesTogether"> <small><span class="itemQty">${value}</span> db ${tireText}</small></span> <span class="setPrice">Ára:</span>`;
                else return `<span class="fourPiecesTogether"> <small><span class="itemQty">${value}</span> db ${tireText} ára</small></span> <span class="setPrice">Akcióban:</span>`;
            });

            discountRateContainer.html(function () {
                if (value <= 4) return `-${discountRateContainer.data('discount').toMoney(0, '.', '.')} <small>Ft-tal</small>`;
                else return `-${((parseInt(discountRateContainer.data('discount')) / 4) * value).toMoney(0, '.', '.')} <small>Ft</small>`;
            });

            arrowText.html(function () {
                if (value <= 3) return '4 db-tól olcsóbb!';
                else if (value == 4) return 'Szettben olcsóbb!';
                else return 'Ennyit spórolhatsz';
            });
        });
    });
    //$(".orederCartButton").removeCartButton();

    $('body').on('click', 'a.removeWheelAssembly', function (e) {
        e.preventDefault();

        const productId = $(this).data('product-id');

        $.ajax({
            url: `/cart/update-assembly/${productId}`,
            type: 'POST',
            dataType: 'JSON',
            data: {
                assembly: false
            },
            success: function (response) {
                console.log(response);
                if (response.result === 'OK') {
                    window.location.reload();
                }
            }
        });
    });

    $('body').on('change', 'input.wheelAssemblyCheckbox', function (e) {
        const productId = $(this).data('product-id');

        console.log('Halleluja');

        $.ajax({
            url: `/cart/update-assembly/${productId}`,
            type: 'POST',
            dataType: 'JSON',
            data: {
                assembly: true
            },
            success: function (response) {
                console.log(response);
                if (response.result === 'OK') {
                    window.location.reload();
                }
            }
        });
    });
})($)