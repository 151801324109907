$(function () {
    const wrapper = $('.felnik-filters-wrapper');

    loadBrands();

    $('body').on('click', 'button.cancelSelect', function (e) {
        e.preventDefault();
        const $select = $(this).prev('select');
        $select.val(-1).change();
    });

    $("#vechicleNotFoundLink").click(function (e) {
        e.preventDefault();
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".need-help").offset().top
        }, 600);
    });

    $(document).on('change', 'select[name="brand"]', function (e) {
        e.preventDefault();
        loadTypes($(this).val());
    });

    $(document).on('change', 'select[name="type"]', function (e) {
        e.preventDefault();
        const brandId = $('select[name="brand"]').val();
        loadModels(brandId, $(this).val());
    });

    $(document).on('change', 'select[name="model"]', function (e) {
        e.preventDefault();

        const width = $(this).find('option:selected').data('width');
        const series = $(this).find('option:selected').data('series');
        const central_bore = $(this).find('option:selected').data('central-bore');

        searchCountHits(width, series, central_bore);
    });

    wrapper.find('.navigateToProductSearch').click(function (e) {
        e.preventDefault();

        let url = $('.mainpage-rims-filters').data('search-navigate-url');
        const category = $('.mainpage-rims-filters').data('category');
        const modelSelect = wrapper.find('select[name="model"]'); 

        const width = modelSelect.find('option:selected').data('width') | null;
        const series = modelSelect.find('option:selected').data('series') | null;
        const central_bore = modelSelect.find('option:selected').data('central-bore') | null;
        const formData = $('.mainpage-rims-filters').data('form-details');

        const params = [];

        if (width != 0)
            params.push(`width=${width}`);

        if (series != 0)
            params.push(`series=${series}`);

        if (central_bore != 0)
            params.push(`central_bore=${central_bore}`);
        
        params.push(`fd=${formData}`);
        
        url = url + `?category=${category}&${params.join('&')}`;

        $.ajax({
            url: `/wheel/save-car-details`,
            type: 'POST',
            dataType: 'JSON',
            data: {
                brand: $('select[name="brand"]').val() != -1 ? $('select[name="brand"] option:selected').html() : null,
                type: $('select[name="brand"]').val() != -1 ? $('select[name="type"] option:selected').html() : null,
                model: $('select[name="brand"]').val() != -1 ? $('select[name="model"] option:selected').html() : null,
            },
            success: function (response) {
                window.location = url;
            }
        });
        
        
    });
});

function loadBrands() {
    $('select[name="brand"]').html('<option value="-1">Autómárka</option>');
    $.ajax({
        url: `/edi/get-brands`,
        type: 'GET',
        dataType: 'JSON',
        success: function (response) {
            jQuery.each(sortResponse(response), function (key, item) {
                $('select[name="brand"]').append(`<option value="${item[0]}">${item[1]}</option>`);
            });
        }
    });
}

function loadTypes(brandId) {
    $('select[name="type"]').html('<option value="-1">Típus</option>');
    $('select[name="model"]').html('<option value="-1">Modell</option>'); 
    
    if (brandId == -1) return;

    $.ajax({
        url: `/edi/${brandId}/get-types`,
        type: 'GET',
        dataType: 'JSON',
        success: function (response) {
            jQuery.each(sortResponse(response), function (key, item) {
                $('select[name="type"]').append(`<option value="${item[0]}">${item[1]}</option>`);
            });
        }
    });
}

function loadModels(brandId, typeId) {
    $('select[name="model"]').html('<option value="-1">Modell</option>');
    
    if (typeId == -1) return;

    $.ajax({
        url: `/edi/${brandId}/${typeId}/get-models`,
        type: 'GET',
        dataType: 'JSON',
        success: function (response) {
            jQuery.each(mapAndSortResponse(response), function (key, item) {
                $('select[name="model"]').append(`<option 
                    value="${item[0]}" 
                    data-width="${item[1]}" 
                    data-series="${item[2]}" 
                    data-central-bore="${item[3]}"
                >${item[4]}</option>`);
            });
        }
    });
}

/**
 * Alphabetical sorting and mapping response by value of models
 * 
 * @param Object response 
 */
function mapAndSortResponse(response) {
    var sortable = [];
    for(let key in response) {
        sortable.push([key, response[key]['width'], response[key]['series'], response[key]['central_bore'], response[key]['text']]);
    }

    return sortable.sort(function(a, b) {
        return a[4].toLowerCase() < b[4].toLowerCase()
                ? -1 
                : a[4].toLowerCase() > b[4].toLowerCase()
                    ? 1 
                    : 0;
    });
}

/**
 * Alphabetical sorting of response by value of brands any types
 * 
 * @param Object response 
 */
function sortResponse(response) {
    var sortable = [];
    for(let key in response) {
        sortable.push([key, response[key]]);
    }

    return sortable.sort(function(a, b) {
        return a[1].toLowerCase() < b[1].toLowerCase()
                ? -1 
                : a[1].toLowerCase() > b[1].toLowerCase()
                    ? 1 
                    : 0;
    });
}

function searchCountHits(width, series, central_bore)
{
    const formData = {
        brand: $('select[name="brand"]').find('option:selected').html(),
        type: $('select[name="type"]').find('option:selected').html(),
        model: $('select[name="model"]').find('option:selected').html()
    };
    
    $.ajax({
        url: `/ajax-rims-search-count-hits`,
        type: 'POST',
        dataType: 'JSON',
        data: {
            width,
            series,
            central_bore,
            formData
        },
        success: function (response) {
            $('.mainpage-rims-filters').attr('data-form-details', response.formData);
            $('.felnik-filters-wrapper').find('.resoultsNumber').html(response.count);
        }
    });    
}