/**
 * handle the overly when start or stop the search.
 * 
 * @param {jQuery} $
 * @param {jQuery<HTMLElement>} htmlElement
 * @param {jQuery<HTMLElement>} bodyElement
 */
function OverlayHandler($, htmlElement, bodyElement) {

    this.bodyElement = bodyElement;

    this.htmlElement = htmlElement;

    let showOverlay = false;


    var freezeVp = function (e) {
        e.preventDefault();
    };


    function stopBodyScrolling(bool, item) {
        if (bool === true) {
            document.getElementById(item).addEventListener("touchmove", freezeVp, false);
        } else {
            document.getElementById(item).removeEventListener("touchmove", freezeVp, false);
        }
    }

    this.showOverlay = function () {

        if (showOverlay == false) {
            $('html').addClass('show-filter');
            $('body').prepend('<div id="overlay"></div>');
            stopBodyScrolling(true, 'overlay');
            showOverlay = true;
        }
    }
    this.hideOverlay = function () {

        if (showOverlay == true) {

            $('html').removeClass('show-filter');
            stopBodyScrolling(false, 'overlay');
            $('#overlay').remove();
            showOverlay = false;
        }
    }
}