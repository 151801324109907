/**
 * Handling the product Search
 * 
 * @param {JQueryStatic<HTMLElement>} $
 * @param {OverlayHandler} overlayHandler 
 * @param {ListPageProductLoader} listPageLoader
 */
function ProductPageSearchHandler($, listPageLoader) {
    if ($ === undefined) {
        throw Error("Jquery is required for this function");
    }

    /**
     * Url of sending datas
     */
    this.url;

    /** If has active query, we dont create a new query.*/
    this.hasActiveQuery = false;


    /** Active page number, if we create a new query*/
    this.activePage = 1;

    /**Numper of per page */
    this.perPage = 5;
    /**
     * Count of all page
     */
    this.countOfAllItem;
    /**
     * next page URl
     */
    this.nextPageUrl;
    /**
     * search parameters
     */
    this.searchParameters;
    /**
     * Contain ordering datas
     */
    this.ordering;

    //This method render the data
    /**
     * 
     * 
     * @type {(responseHtml:string)=>void} 
     */
    this.drawingCallback;

    /** 
     * Create a Query by the search parameter and the ordeing
     * 
     * @param {()=>void} callback This function is calling after succes request
     */
    this.newSearchQuery = function (searchParameters) {
        this.activePage = 1;

        this.setSearchParameters(searchParameters);
        let ajaxConfig = { url: "/ajaxSearch" };

        if ($("form.product-complex-filter").length > 0) {
          ajaxConfig = { url: $("form.product-complex-filter").attr('action') };
        }

        ajaxConfig.data = this.queryPatameter();
        
        listPageLoader.showLoader();

        this.hasActiveQuery = true;

        let self = this;

        return $.ajax(ajaxConfig).then(function(response)  {
            if (response.result != undefined && response.result.url != undefined) {
                self.nextPageUrl = response.result.url.next == "" || response.result.url.next == null ? undefined : response.result.url.next;
                window.history.pushState(null, "", response.result.url.current);
            }
            return response;
        }).always(function () {
            //overlayHandler.hideOverlay();
            listPageLoader.hideLoader();
            self.hasActiveQuery = false;
        });
    }

    this.setSearchParameters = function (data) {
        this.searchparameters = data;
    }

    this.setOrdering = function (ordering) {
        this.ordering = ordering;
    }

    /** 
     *
     */
    this.getNextFromServer = function () {

        if (!this.hasActiveQuery && this.nextPageUrl) {

            this.hasActiveQuery = true;
            listPageLoader.showLoader();

            let self = this;

            return $.ajax({
                url: this.nextPageUrl,
                data: {
                    currentUrl: $('form.productComplexFilter').data('current-url')
                }
            }).then(function (response) {
                if (response.result != undefined && response.result.url != undefined) {
                    self.nextPageUrl = response.result.url.next;
                    if (response.result.url.current !== undefined) {
                        window.history.pushState(null, "", response.result.url.current);
                    }
                }

                return response;

            }).always(function () {
                listPageLoader.hideLoader();
                self.hasActiveQuery = false;
            });
        }

        return undefined;
    }
    /** 
     * Convert order and query paramters to one object, for the query
     * 
     */
    this.queryPatameter = function () {

        let output = [];
        for (let formItem of this.searchparameters) {
            output.push(formItem);
        }

        for (let formItem of this.ordering) {
            output.push(formItem);
        }
        return output;
    }
    /**
     * 
     * Find the inputs, and if has checked attribute set the props to checked
     * 
     */
    this.setSelectionsByHtml = function () {
        let inputs = $('input');

        for (let input of inputs) {
            input = $(input);
            let checked = input.attr('checked');
            if (checked !== undefined && checked != false) {
                input.prop('checked', true);
            }
        }


        let selects = $('select');

        for (let select of selects) {
            //console.log("select", select);
            select = $(select);
            let selected = select.find('option:selected');
            //console.log("selected", selected)
            select.val($(selected).val());
        }

    }
 
    this.setSelectionsByHtml();
};

/**
 * Help for rendering list set the count etc. 
 * 
 * @param {JQueryStatic<HTMLElement>} $  
 * @param {string} productsHtml Products html
 * @param {number} count count of finded targets
 * @param {string} specialHtml Heightlighted product
 */
function listRenderHelper($, productsHtml, count, specialHtml) {

    $('.productSearchResoultsCount').html(count);
    $('.productSearchHeightLightedProduct').html(specialHtml);
    $('.productSearchResoultsListing').html(productsHtml ? productsHtml : "");

    if (count > 0) {
        $('.productSearchResoultsShowNotFound').addClass('hidden');
    } else {
        $('.productSearchResoultsShowNotFound').removeClass('hidden');
    }

    initButtonsAndPopups();
}


function filtersRenderHelper($, filters, ignoreFilter) {

    let productComplexFilter = $('.productComplexFilter');

    for (let filterName in filters) {
        let filterElement = productComplexFilter.find('.' + filterName);

        if (ignoreFilter != filterName) {
            $(filterElement).html(filters[filterName]);
        }
    }
}


function initButtonsAndPopups() {
    $('.popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-with-zoom',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        disableOn: function () {
            if ($(window).width() < 600) {
                return true;
            }
            return true;
        }
    });
    $('.popup-image').magnificPopup({
        type: 'image',
        mainClass: 'mfp-with-zoom producImage',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        image: {
            markup: '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="info-bar"></div>' +
                '<div class="mfp-img"></div>' +
                '</div>',
            verticalFit: true,
            cursor: ''
        },
        callbacks: {
            markupParse: function (template, values, item) {
                template.find('.info-bar').html('');
                var logo = item.el.data("logo");
                var logo_out = '';
                var title = item.el.data("title");
                var title_out = '';
                var meret = item.el.data("meret");
                var meret_out = '';
                var link = item.el.data("link");
                var link_out = '';
                if (logo.length > 0) {
                    logo_out = '<img src="' + logo + '">';
                }
                if (title.length > 0) {
                    title_out = '<h2>' + title + '</h2>';
                }
                if (meret.length > 0) {
                    meret_out = '<strong>' + meret + '</strong>';
                }
                if (link.length > 0) {
                    link_out = '<a href="' + link + '">Részletek &raquo;</a>';
                }
                template.find('.info-bar').append('<table><tr><td>' + logo_out + '</td><td class="title">' + title_out + '</td><td>' + meret_out + '</td><td>' + link_out + '</tr></table>');
            }
        }
    });
}

function responseHandlerHelper(response, ignoreFilter) {
    if (response.status = "OK") {
        
        if (response.result.floatedWheelBottomBarHtml == null) {
            $('#floatedWheelBottomBar').fadeOut(300, "linear", function () {
                $('#floatedWheelBottomBar').remove();
            });
        } else {
            $('.product-list-wrapper').append(response.result.floatedWheelBottomBarHtml);  
        }

        listRenderHelper($, response.result.results, response.result.resultCount != "" ? response.result.resultCount : 0, response.result.special);
        filtersRenderHelper($, response.result.filters, ignoreFilter);

    } else {
        console.log("The query is not complete!");
    }
}

function removeEmptys(formSetializedArray) {
    let output = [];

    for (let item of formSetializedArray) {
        if (item.value !== undefined && item.value !== "" && item.value != null) {
            output.push(item);
        }
    }

    return output;
}
/**
 * 
 * @param {JQueryStatic<HTMLElement>} $ 
 * @param {ProductPageSearchHandler} productPageSearchHandler 
 * 
 */
var productListPageSearchInit = function ($, productPageSearchHandler, productLoader) {

    //Init mobile filter button!

    $('.filter_btn').on('click', function (e) {
        e.preventDefault();
        if ($('html').hasClass('show-filter')) {
            $('html').removeClass('show-filter');
            $('#overlay').remove();
        } else {
            $('html').addClass('show-filter');
            $('body').prepend('<div id="overlay"></div>');
        }
        return false;
    });

    $('body').on('click', '#overlay', function () {
        $('html').removeClass('show-filter');
        $('#overlay').remove();
    });

    $('.close_filter_btn').click(function () {
        $('html').removeClass('show-filter');
        $('#overlay').remove();
    });



    let ignoreFilter;
    $(".productSearchResoultsListing").getCartModal({
        delegate: ".addToCartButtonProductList",
        method: 'POST',
        eventCb: function (self) {
            let output = {
                url: '/cart/product/show-cart-modal/' + $(self).data('product-id'),
                data: {
                    quantity: typeof $(self).data('stock-sale') !== 'undefined' ? $(self).data('stock-sale') : 4 
                }
            };

            //Ecommerce plugin
            addToCart({
                id: $(self).data('id'),
                name: $(self).data('name'),
                category: $(self).data('category'),
                brand: $(self).data('brand'),
                quantity: 4
            });
            return output;
        }
    });

    initButtonsAndPopups();

    let searchForm = $('.productComplexFilter');
    let productListOrdering = $('.productListOrdering');

    if (location.search.charAt(0) == "?") {
        productPageSearchHandler.nextPageUrl = "/ajaxSearch" + location.search + "&page=2&perPage=5";
    } else {
        productPageSearchHandler.nextPageUrl = "/ajaxSearch" + "?page=2&perPage=5";
    }

    //Disable the scrolling if has query error.
    let disabledScroll = false;

    searchForm.submit(function (e) {
        e.preventDefault();

        productPageSearchHandler.setOrdering(
            removeEmptys(
                productListOrdering.serializeArray()
            )
        );

        productPageSearchHandler.newSearchQuery(
            removeEmptys(
                searchForm.serializeArray()
            )
        ).then(function (response) {
            disabledScroll = false;
            responseHandlerHelper(response, ignoreFilter);
            //this.setSelectionsByHtml();
        }).catch(function (error) {
            console.log("error:", error);
        });

        //$('html, body').animate({
        //    scrollTop: 0
        //}, 'fast');
    });


    $('body').delegate('.productComplexFilter input', 'change', function (event) {
        event.preventDefault();

        let filterWrapper = $(this).parents('.filterWrapper');

        ignoreFilter = filterWrapper.data('filter-name');

        let inputs = filterWrapper.find('input');

        let selectedNumber = 0;

        for (let input of inputs) {
            if ($(input).prop('checked')) {
                selectedNumber++;
            }
        }
        if (selectedNumber > 0) {
            $('.productComplexFilter').submit();
        } else {
            let resetButton = $('.productComplexSearchReset[data-reset-filter=' + filterWrapper.data('filter-name') + ']');
            console.log(resetButton);
            $(resetButton).click();
        }


    });

    $('body').delegate('.productComplexFilter select', 'change', function (event) {
        event.preventDefault();
        ignoreFilter = undefined;
        //ignoreFilter = $(this).parents('.filterWrapper').data('filter-name');

        $('.productComplexFilter').submit();
    });

    $('body').on('click', '.select-wrapper .cancelSelect.sizeSelect', function (e) {
        e.preventDefault();
        
        $(this).prev('select[name="size"]').val(-1);
        $(this).prev('select[name="size"]').change();
    });

    $('body').on('click', '.select-wrapper .cancelSelect.diameterSelect', function (e) {
        e.preventDefault();
        
        $(this).prev('select[name="diameter"]').val(-1);
        $(this).prev('select[name="diameter"]').change();
    });

    $('body').on('click', '.productComplexSearchReset', function () {
        //let category = searchForm.find('input[name="category"]');
        //let categoryValue = category.val();
        //
        //if (searchForm[0] !== undefined) {
        //    let inputs = searchForm.find("input");
        //    let options = searchForm.find("option");
        //    for (let input of inputs) {
        //        $(input).removeAttr('checked').prop('checked', false);
        //    }
        //    for (let option of options) {
        //        $(option).removeAttr('selected').prop('selected', false);
        //    }
        //}
        //
        //category.val(categoryValue);
        let filter = $(this).data('reset-filter');
        ignoreFilter = undefined;

        console.log(filter)

        if (filter) {
            let productComplexFilter = $('.productComplexFilter');
            let filtersToReset = productComplexFilter.find('.' + filter);

            let inputs = filtersToReset.find('input');

            if (inputs.length > 0) {
                for (let input of inputs) {
                    $(input).removeAttr('checked').prop('checked', false);
                }
            }

            let selects = filtersToReset.find('select');

            if (selects.length > 0) {
                for (let select of selects) {
                    $(select).val('');
                }
            }


            let options = filtersToReset.find('options');

            if (options.length > 0) {
                $(options).removeAttr('selected');
            }

        }

        searchForm.submit();
    });

    productListOrdering.change(function () {

        searchForm.submit();
    });

    scrollCheck(function () {
        if (!disabledScroll) {
            let nextPageQuery = productPageSearchHandler.getNextFromServer()

            if (nextPageQuery) {
                nextPageQuery.then((response) => {
                    if (response !== null) {
                        
                        let products = [];
                        $(response.result.results).find('.product-url-list-page').each(function () {
                            products.push($(this).data());
                        });
                        //Enhanced Ecommerce plugin
                        addImpressions(products);

                        $('.productSearchResoultsListing').append(response.result.results);
                        initButtonsAndPopups();
                    }
                }).catch((error) => {
                    console.error(error);
                    disabledScroll = true;
                });
            }
        }
    });
}

function scrollCheck(callback) {

    let timeout;
    let targetElement = $(".productSearchResoultsListing");
    if (targetElement.length > 0) {
        $(window).scroll(function () {
            if ($(window).scrollTop() + $(window).height() > targetElement.position().top + targetElement.height() - 200) {
                if (timeout !== undefined) {
                    clearInterval(timeout);
                }
                timeout = setTimeout(callback, 200);
            }
        });
    }
}

/**
 * JQuery 3.2.1
 */
productListPageSearchInit(jQuery,
    new ProductPageSearchHandler(
        $,
        //new OverlayHandler($, $('html'), $('body'))
        new ListPageProductLoader($)
    )
);